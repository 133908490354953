import React from 'react';

import './learn-more.scss';
import { TranslatedBlock } from './language';

export default function LearnMore() {
  return (
    <section className="learn-more">
      <div className="container">
        <div className="row">
          <TranslatedBlock language="english">
            <div className="col-12 col-lg-5 p-5">
              <p className="h2 mb-4">How do I learn more?</p>
              <p>Talk to your eye doctor about enrolling you into the Our Vision Care Program.</p>
              <p>
                Call the Our VISION Care Patient Assistance Program at{' '}
                <a href="tel:1-844-844-3371">
                  <b>1-844-844-3371</b>
                </a>{' '}
                to learn more about the services that they offer.
              </p>
            </div>
            <div className="col-12 col-lg-5 offset-lg-1 p-5 text-white d-flex align-items-center">
              <q>Talk to your eye doctor if you have specific questions.</q>
            </div>
          </TranslatedBlock>
          <TranslatedBlock language="french">
            <div className="col-12 col-lg-5 p-5">
              <p className="h2 mb-4">Comment puis-je obtenir plus d’information?</p>
              <p>Consultez votre ophtalmologiste à propos de l’inscription au programme Nos soins de la VUE.</p>
              <p>
                Téléphonez au{' '}
                <a href="tel:1-844-844-3371">
                  <b>1-844-844-3371</b>
                </a>{' '}
                pour en savoir plus sur les services offerts dans le cadre du programme d’assistance aux patients Nos
                soins de la VUE.
              </p>
            </div>
            <div className="col-12 col-lg-5 offset-lg-1 p-5 text-white d-flex align-items-center">
              <q>Consultez votre ophtalmologiste si vous avez des questions.</q>
            </div>
          </TranslatedBlock>
        </div>
      </div>
    </section>
  );
}
